import { urlCsrfToken } from '@/api/endpoint'

export async function fetchCsrf(csrfToken: { value: string }) {
  let response

  try {
    const responseCsrf = await fetch(urlCsrfToken, {
      method: 'GET',
      credentials: 'include'
    })
    response = await responseCsrf.json()
    csrfToken.value = response.csrf_token
  } catch (e) {
    return
  }
}
