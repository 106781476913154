import { ref } from 'vue'
import { decodeBase64Url } from './decodeBase64Url'
import { URL_BACK } from '@/config/constants'
import customer from '@/storage/customer'
import address from '@/storage/address'
import orders from '@/storage/orders'
import { fetchCsrf } from '../csrf/fetchCsrf'
import { fetchPostAuthorization } from '../fetch/fetchPostAuthorization'
import { urlCustomerFind } from '@/api/endpoint'

export async function fetchUserWithToken(token: string) {
  if (!token) return null

  const parts = token.split('.')
  if (parts.length !== 3) return true

  const payloadBase64Url = parts[1]
  const payloadBase64 = decodeBase64Url(payloadBase64Url)
  const payload = JSON.parse(atob(payloadBase64))
  const customerId = payload.uid
  const storeId = payload.storeId

  const data = {
    store_id: storeId,
    customer_id: customerId
  }

  try {
    const res = await fetchPostAuthorization(urlCustomerFind, data)

    if (res.class === 'success') {
      const response = res
      customer.setCustomerData(response.user)
      address.setAddressData(response.user.addresses)
      orders.setOrderData(response.user.orders)

      return response.user
    } else {
      return null
    }
  } catch (e) {
    return null
  }
}
