<script setup lang="ts">
import Header from '@/components/main/Header.vue'
import Footer from '@/components/main/Footer.vue'
import stores from './storage/stores'
import SectionOrder from '@/components/section/SectionOrder.vue'
import AlertDeleteElement from '@/components/alert/AlertDeleteElement.vue'
import AlertClearBasket from '@/components/alert/AlertClearBasket.vue'
import ButtonTop from '@/components/button/ButtonTop.vue'
import SectionOverlay from '@/components/alert/SectionOverlay.vue'
import SpinerLoader from './components/main/SpinerLoader.vue'
import AlertBasketLogin from './components/alert/AlertBasketLogin.vue'
import { defineAsyncComponent, onMounted, provide, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { applyTheme, theme } from './event/theme/theme'
import { loadData } from './utils/loadData'
import { dataLoaded } from './event/dataLoaded'
import { watchersApp } from './utils/watchers/app/watchersApp'
// import { io, Socket } from 'socket.io-client'
import { formatStoreName } from '@/utils/store/formatStoreName'
import { cartBus } from '@/event/cartBus'
import { configTelephone } from './storage/setting/configTelephone'
import { logo } from './utils/main/navbar/statesNavbar'
import { URL_IMAGE } from './config/constants'
import { mostRecentOrder } from '@/event/order/mostRecentOrder'
import { waitingEvent } from '@/event/order/success/waitingEvent'
import { showSectionManagement } from './event/main/app/showSectionManagement'

const Error = defineAsyncComponent(() => import('@/components/error/Error.vue'))

// import Symbol from './components/symbolSvg/symbol.vue'

const route = useRoute()


watchersApp(route)


onMounted(async () => {
  const savedTheme = localStorage.getItem('theme')
  if (savedTheme) {
    applyTheme(savedTheme)
  }


  await loadData()

  watch(
    [() => route.name, () => dataLoaded.value],
    ([routeName, dataLoadedValue]) => {
      if (dataLoadedValue === 2) {
        const store = stores.state.storeData.name
        if (store) {
          const storeName = formatStoreName(store)
          const cartKey = `${storeName}_cart`

          if (routeName === 'success-payment') {
            cartBus.value = []
            localStorage.removeItem(cartKey)
          }
        }
      }
    }
  )

  // const storeId = stores.state.storeData.store_id
})
function generateSecretKey(length = 32) {
  // Vérifie que la longueur est un nombre entier
  if (typeof length !== 'number' || length <= 0) {
    throw new Error("La longueur de la clé doit être un nombre positif.");
  }

  // Crée une clé secrète aléatoire en utilisant crypto.getRandomValues
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);

  // Convertir les octets en une chaîne hexadécimale
  return Array.from(array).map(byte => byte.toString(16).padStart(2, '0')).join('');
}

const secretKey = generateSecretKey(); // Par défaut, génère une clé de 32 caractères
console.log(secretKey);



</script>

<script lang="ts">

</script>

<template>
  <div class="content-site" v-if="showSectionManagement === false">
    <div class="w-full h-full flex flex-col items-center">
      <Header />

      <SectionOverlay />

      <AlertDeleteElement v-if="theme == 'default'" />
      <AlertClearBasket v-if="theme == 'default'" />
      <AlertBasketLogin v-if="theme == 'default'" />

      <SectionOrder v-if="theme == 'default'" />

      <RouterView v-if="dataLoaded == 2" class="router-view-class" />
      <Error v-else-if="dataLoaded == 1" />
      <SpinerLoader v-else />
      <!-- <HamburgerLogo /> -->
      <!-- <SpinerLoader /> -->
    </div>
    <ButtonTop />

    <Footer />
    <!-- <Symbol /> -->
  </div>


  <section class="section-management" v-else>

    <div v-if="logo">
      <img v-if="logo" width="100px" :src="`${URL_IMAGE}${logo}`" alt="" />
    </div>

    <div class="p-2 flex items-start">
      <svg class="svg-settings animate-rotate-clockwise" viewBox="0 0 800 801" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M800 450.176V350.176L680.475 300.376C677.15 291.001 673.65 281.926 669.35 273.026L717.6 152.901L646.875 82.2012L527.825 131.226C518.75 126.826 509.475 123.126 499.9 119.701L449.225 0.951172H349.225L299.8 119.301C289.85 122.826 280.275 126.526 270.8 131.026L151.95 83.2762L81.25 153.976L129.7 271.751C125 281.426 121.2 291.276 117.575 301.451L0 351.726V451.726L117.675 500.751C121.3 510.901 125.2 520.776 129.875 530.451L82.325 649.001L153.025 719.701L271.1 671.076C280.575 675.576 290.25 679.176 300.2 682.601L350.775 800.951H450.775L500.275 682.001C509.75 678.476 519.125 674.776 528.1 670.476L648.025 718.526L718.725 647.826L669.5 528.501C673.7 519.526 677.125 510.426 680.45 501.151L800 450.176ZM399.225 550.951C316.425 550.951 249.225 483.751 249.225 400.951C249.225 318.151 316.425 250.951 399.225 250.951C482.025 250.951 549.225 318.151 549.225 400.951C549.225 483.751 482.025 550.951 399.225 550.951Z" />
      </svg>
      <svg class="w-8 fill-white animate-rotate-counterclockwise" viewBox="0 0 800 801"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M800 450.176V350.176L680.475 300.376C677.15 291.001 673.65 281.926 669.35 273.026L717.6 152.901L646.875 82.2012L527.825 131.226C518.75 126.826 509.475 123.126 499.9 119.701L449.225 0.951172H349.225L299.8 119.301C289.85 122.826 280.275 126.526 270.8 131.026L151.95 83.2762L81.25 153.976L129.7 271.751C125 281.426 121.2 291.276 117.575 301.451L0 351.726V451.726L117.675 500.751C121.3 510.901 125.2 520.776 129.875 530.451L82.325 649.001L153.025 719.701L271.1 671.076C280.575 675.576 290.25 679.176 300.2 682.601L350.775 800.951H450.775L500.275 682.001C509.75 678.476 519.125 674.776 528.1 670.476L648.025 718.526L718.725 647.826L669.5 528.501C673.7 519.526 677.125 510.426 680.45 501.151L800 450.176ZM399.225 550.951C316.425 550.951 249.225 483.751 249.225 400.951C249.225 318.151 316.425 250.951 399.225 250.951C482.025 250.951 549.225 318.151 549.225 400.951C549.225 483.751 482.025 550.951 399.225 550.951Z" />
      </svg>
      <svg class="svg-settings animate-rotate-clockwise" viewBox="0 0 800 801" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M800 450.176V350.176L680.475 300.376C677.15 291.001 673.65 281.926 669.35 273.026L717.6 152.901L646.875 82.2012L527.825 131.226C518.75 126.826 509.475 123.126 499.9 119.701L449.225 0.951172H349.225L299.8 119.301C289.85 122.826 280.275 126.526 270.8 131.026L151.95 83.2762L81.25 153.976L129.7 271.751C125 281.426 121.2 291.276 117.575 301.451L0 351.726V451.726L117.675 500.751C121.3 510.901 125.2 520.776 129.875 530.451L82.325 649.001L153.025 719.701L271.1 671.076C280.575 675.576 290.25 679.176 300.2 682.601L350.775 800.951H450.775L500.275 682.001C509.75 678.476 519.125 674.776 528.1 670.476L648.025 718.526L718.725 647.826L669.5 528.501C673.7 519.526 677.125 510.426 680.45 501.151L800 450.176ZM399.225 550.951C316.425 550.951 249.225 483.751 249.225 400.951C249.225 318.151 316.425 250.951 399.225 250.951C482.025 250.951 549.225 318.151 549.225 400.951C549.225 483.751 482.025 550.951 399.225 550.951Z" />
      </svg>
    </div>
    <div class="div-management">
      <div class="flex flex-col w-full items-center">
        <p class="text-important">Veuillez nous excuser !</p>
        <p class="text-important">Le site est en maintenance. </p>
      </div>

      <p class="text-important">
        Nous nous efforçons de résoudre les problèmes dans les plus
        brefs délais.
      </p>

      <div class="flex flex-col w-full items-center">
        <p v-if="configTelephone">Vous pouvez nous contacter directement au :
          <a class="text-lime-eronor" :href="`tel:${configTelephone}`">{{
            configTelephone }}</a>
        </p>
        <p>Merci de votre compréhension.</p>
      </div>
    </div>
  </section>
</template>

<style scoped>
/* */

.content-site {
  @apply flex flex-col items-center justify-between min-h-screen;
}

.router-view-class {
  @apply min-w-full
}

.section-management {
  @apply gap-3 p-2 min-w-full min-h-screen text-center flex flex-col items-center justify-center
}

.svg-settings {
  @apply w-12 fill-white
}

.animate-rotate-clockwise {
  animation: rotateClockwise 5s linear infinite;
}

.animate-rotate-counterclockwise {
  animation: rotateCounterclockwise 5s linear infinite;
}

.div-management {
  @apply flex flex-col h-full gap-6 items-center
}

.text-important {
  @apply text-lg font-bold
}


@media screen and (min-width: 1030px) {
  .router-view-class {
    @apply min-w-[60rem] w-[61rem];
  }
}

@media screen and (min-width: 1280px) {
  .router-view-class {
    @apply min-w-[65rem] w-[66rem];
  }
}

@media screen and (min-width: 1500px) {
  .router-view-class {
    @apply min-w-[75rem] w-[75rem];
  }
}

@media screen and (min-width:2000px) {
  .router-view-class {
    @apply min-w-[80rem] w-[80rem]
  }
}


@keyframes rotateClockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Animation antihoraire */
@keyframes rotateCounterclockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

/* .side-bar-active {
  opacity: 1;
} */
</style>
