import { messageAlert } from '@/event/alert/messageAlert'
import { messageCancelDelete } from '../messageCancelDelete'
import { showAlertCancelDelete } from '../showAlertCancelDelete'

export function toggleAlertCancelDelete() {
  showAlertCancelDelete.value = !showAlertCancelDelete.value
  if (showAlertCancelDelete.value === false) {
    messageCancelDelete.value = messageAlert
  }
}
