import { isAuthenticated } from '@/event/account/auth/authentification'
import { showNavAccountLogin } from '@/event/account/alert/showNavAccountLogin'
import stores from '@/storage/stores'
import { formatStoreName } from '../store/formatStoreName'
import customer from '@/storage/customer'
import router from '@/router'

export function disconnect() {
  if (isAuthenticated.value === true) {
    if (showNavAccountLogin.value === true) {
      showNavAccountLogin.value = !showNavAccountLogin.value
    }
    isAuthenticated.value = false

    const store = stores.getStoresData().name

    customer.clearCustomerData()
    if (store) {
      const storeName = formatStoreName(store)
      localStorage.removeItem(`${storeName}_jwt`)
      localStorage.removeItem(`${storeName}_refresh_token`)
    }

    router.push({ name: 'racine' })
  }
}
