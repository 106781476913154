import { URL_BACK } from '@/config/constants'
import { ref } from 'vue'
import { fetchCsrf } from '../csrf/fetchCsrf'
import { isAuthenticated } from '@/event/account/auth/authentification'
import { isNotAuthenticated } from '@/event/account/auth/isNotAuthenticated'
import stores from '@/storage/stores'
import { formatStoreName } from '../store/formatStoreName'
import { fetchPost } from '../fetch/fetchPost'
import { urlJwtRefreshToken } from '@/api/endpoint'
const csrfToken = ref()

export async function fetchRefreshToken(refreshToken: string) {
  try {
    const data = { refresh_token: refreshToken, store_id: stores.getStoresData().store_id }

    const res = await fetchPost(urlJwtRefreshToken, data)
    const response = res

    if (res.class === 'success' && response.jeton) {
      const store = stores.getStoresData().name

      if (store) {
        const storeName = formatStoreName(store)
        localStorage.setItem(`${storeName}_jwt`, response.jeton)
        localStorage.setItem(`${storeName}_refresh_token`, response.refresh_token)
      } else {
        localStorage.setItem('jwt', response.jeton)
        localStorage.setItem('refresh_token', response.refresh_token)
      }
      isAuthenticated.value = true
    } else {
      isNotAuthenticated.value = 1
    }
  } catch (error) {}
}
