<script setup lang="ts">
import ButtonBack from '@/components/button/ButtonBack.vue';
import ButtonClose from '@/components/button/ButtonClose.vue';
import { configEmail } from '@/storage/setting/configEmail';
import stores from '@/storage/stores';
import { toggleShowPrivacyPolicyModal } from '@/utils/toggle/information/toggleShowPrivacyPolicyModal';
import { computed } from 'vue';

const storeName = computed(() => stores.getStoresData().name)
const dataPrivacyPolicy = computed(() => stores.getStoresData().privacy_policy)
const LastDateModified = computed(() => {
    if (!dataPrivacyPolicy.value || dataPrivacyPolicy.value.length === 0) {
        return '';
    }

    const latestDate = dataPrivacyPolicy.value.reduce((latest, current) => {
        const currentDate = new Date(current.date_modified);
        return (currentDate > latest) ? currentDate : latest;
    }, new Date(0));

    return latestDate.toLocaleDateString('fr-FR', {
        weekday: 'long', // Jour de la semaine en toutes lettres
        day: 'numeric',  // Jour du mois sous forme numérique
        month: 'long',   // Mois en toutes lettres
        year: 'numeric'  // Année sous forme numérique
    }); // Exemple : "lundi 5 février 2025"
});
</script>

<template>
    <section class="flex justify-center pt-2">
        <section class="section-privacy-policy">
            <div class="w-full flex justify-end">
                <ButtonClose :function="toggleShowPrivacyPolicyModal" :hover-color="'lime'" />
            </div>
            <div class="div-h2-privacy-policy">
                <h2 class="h2-privacy-policy">Politique de confidentialité</h2>
            </div>
            <section class="section-content-privacy-policy">
                <div class="div-first-content-privacy-policy">
                    Dernière mise a jour : <span class="span-date-privacy-policy">{{ LastDateModified }}</span>
                </div>

                <div class="div-header-content-privacy-policy">
                    <p>Bienvenue sur <span class="text-lime-eronor font-medium">{{ storeName }}</span>, Nous proposons
                        un service de commande en ligne.</p>

                    <p>La présente politique de confidentialité explique comment nous collectons, utilisons et
                        protégeons vos données personnelles.</p>
                </div>

                <section class="section-second-content-privacy-policy">
                    <div v-for="(pp, index) in dataPrivacyPolicy" :key="index" class="flex flex-col gap-2">
                        <h3 class="h3-second-content-privacy-policy">{{ (index + 1) + '. ' + pp.title }}</h3>
                        <p v-html="pp.content"></p>
                    </div>
                </section>

                <div>
                    <p>Contact : <a :href="`mailto:${configEmail}`" class="text-lime-eronor hover:underline">{{
                        configEmail }}</a></p>
                </div>
            </section>
        </section>
    </section>
</template>

<style scoped>
.section-privacy-policy {
    @apply absolute -top-32 rounded-md mb-2 z-30 w-full flex flex-col items-center px-4 gap-2 py-3 bg-slate-eronor
}

.div-h2-privacy-policy {
    @apply w-full flex justify-center
}

.h2-privacy-policy {
    @apply font-bold text-2xl
}

.section-content-privacy-policy {
    @apply py-3 flex flex-col gap-2
}

.div-first-content-privacy-policy {
    @apply w-full pt-4 font-semibold
}

.span-date-privacy-policy {
    @apply font-bold text-lime-eronor
}

.div-header-content-privacy-policy {
    @apply whitespace-pre-wrap
}

.section-second-content-privacy-policy {
    @apply w-full flex flex-col gap-3 pt-2
}

.h3-second-content-privacy-policy {
    @apply font-semibold underline
}

@media screen and (min-width: 1400px) {
    .section-privacy-policy {
        @apply max-w-[55rem]
    }
}
</style>