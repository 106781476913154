import { isAuthenticated } from '@/event/account/auth/authentification'
import { showNavAccount } from '@/event/account/alert/showNavAccount'
import { cartBus } from '@/event/cartBus'
import { categoryIdBus } from '@/event/category/categoryIdBus'
import { categoryNameBus } from '@/event/category/categoryNameBus'
import { dashboardBus } from '@/event/dashboard/dashboardBus'
import { dataLoaded } from '@/event/dataLoaded'
import { isNavbarVisible } from '@/event/isNavbarVisible'
import { showDropdownSidebarCategory } from '@/event/main/navbar/showDropdownSidebarCategory'
import { productBus } from '@/event/product/productBus'
import { productIdBus } from '@/event/product/productIdBus'
import { selectionsBus } from '@/event/product/selectionsBus'
import { sectionOrderBus } from '@/event/sectionOrderBus'
import router from '@/router'
import {
  excludeRouteDropdownCategoryArray,
  excludeRouteShowNavbar,
  excludeRouteShowSectionOrderBar
} from '@/storage/navbar/excludeRoute'
import { selectLinkAriane } from '@/utils/ariane/selectLinkAriane'
import { selectLinkArianeOrder } from '@/utils/ariane/selectLinkArianeOrder'
import { checkAndRefreshToken } from '@/utils/jwt/checkAndrefreshToken'
import { goOutLogin } from '@/utils/main/navbar/goOutLogin'
import {
  shouldShowSectionOrder,
  shouldShowSectionOrderDesktop
} from '@/utils/main/navbar/shouldShowSectionOrder'
import { clearSelection } from '@/utils/product/clearSelection'
import { watch } from 'vue'
import type { RouteLocationNormalizedLoaded } from 'vue-router'

export function watcherNavbarMobileDesktop(route: RouteLocationNormalizedLoaded) {
  const handleLoginRedirect = (routePath: string) => {
    if (/\/dashboard|\/basket\/order/.test(routePath) && !isAuthenticated.value) {
      router.push('/')
    }
  }

  watch(
    [() => route.name, () => route.path, () => dataLoaded.value],
    ([routeName, routePath, dataLoadedValue]) => {
      // console.log(isAuthenticated.value)

      if (dataLoaded.value == 2 || isAuthenticated.value === true) {
        checkAndRefreshToken()
      }

      if (routeName !== 'login') {
        showNavAccount.value = false
      }

      if (routeName !== 'register') {
        showNavAccount.value = false
      }

      if (routeName !== 'product') {
        selectionsBus.value = []
      }

      dashboardBus.value = routePath.includes('dashboard')

      selectLinkAriane(routePath)
      selectLinkArianeOrder(routePath)

      if (routeName !== 'catalog-product' && routeName !== 'product') {
        categoryIdBus.value = 0
        categoryNameBus.value = ''
      }
      if (routeName !== 'product') {
        productIdBus.value = 0
        productBus.value = null
        clearSelection()
      }

      handleLoginRedirect(routePath)

      if (goOutLogin(routeName)) {
        router.push('/')
      }

      if (routeName) {
        const routeNameString = String(routeName)
        if (excludeRouteDropdownCategoryArray.includes(routeNameString)) {
          showDropdownSidebarCategory.value = true
        } else {
          showDropdownSidebarCategory.value = false
        }

        if (!excludeRouteShowNavbar.includes(routeNameString) && dataLoadedValue === 2) {
          isNavbarVisible.value = true
          if (shouldShowSectionOrder(routeName, cartBus.value)) {
            sectionOrderBus.value = true
          } else {
            sectionOrderBus.value = false
          }
        } else if (window.innerWidth > 800 && dataLoadedValue === 2) {
          isNavbarVisible.value = true
          if (shouldShowSectionOrderDesktop(routeName, cartBus.value)) {
            sectionOrderBus.value = true
          } else {
            sectionOrderBus.value = false
          }
        } else if (
          isNavbarVisible.value == true &&
          !excludeRouteShowSectionOrderBar.includes(routeNameString)
        ) {
          sectionOrderBus.value = true
        }
      }
    },
    { deep: true }
  )
}
