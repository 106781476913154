<script setup lang="ts">
import { useStoreInfo } from '@/utils/useStoreInfo'
import Navbar from '@/components/main/Navbar.vue'
import { storeHeader } from '@/event/main/header/storeHeader'
import { watchersHeader } from '@/utils/watchers/header/watchersHeader'
import { configWebMessageSpecial } from '@/storage/setting/configWebMessageSpecial'
import { configWebMessageSpecialStatus } from '@/storage/setting/configWebMessageSpecialStatus'

const { updateStore, storeData, testDay, testHour, testMinute } = useStoreInfo()

watchersHeader()

</script>

<template>
  <header>
    <section class="section-header" :class="storeHeader.classTitle">
      <h1 class="text-h1">{{ storeHeader.title }}</h1>
      <p v-if="storeHeader.text" class="text">{{ storeHeader.text }}</p>
    </section>
    <section class="section-message-event animate-background-message-special" :class="{
      'message-special-success': configWebMessageSpecialStatus == 1 && configWebMessageSpecial.length !== 0,
      'message-special-info': configWebMessageSpecialStatus == 2 && configWebMessageSpecial.length !== 0,
      'message-special-cancel': configWebMessageSpecialStatus == 3 && configWebMessageSpecial.length !== 0


    }" v-if="configWebMessageSpecialStatus !== '0' && configWebMessageSpecial.length !== 0">
      <h2 class="h2-message-event animate-text">
        {{ configWebMessageSpecial }}
      </h2>
    </section>

    <Navbar />
  </header>
</template>

<style scoped>
header {
  @apply w-full;
}

.section-header {
  @apply flex flex-col items-center;
  line-height: 1.5;
}

.content-site {
  @apply min-h-screen flex flex-col;
}

.text-h1 {
  @apply text-center font-semibold;
}



.h2-message-event {
  @apply w-full text-center text-base opacity-100 font-semibold text-white-eronor absolute text-nowrap
}

.default-siege {
  @apply bg-amber-eronor px-3 py-2 flex justify-center text-gray-800;
}

.open-store {
  @apply bg-lime-eronor px-3 py-2 flex justify-center;
}

.close-store {
  @apply bg-red-eronor px-3 py-2 flex justify-center;
}

.text {
  @apply underline;
}

.animate-background-message-special {
  animation: animateBackground 10s ease-in-out infinite;
}

.animate-text {
  animation: slideText 10s ease-in-out infinite;
  will-change: transform;
}


.message-special-success {
  @apply bg-blue-500
}

.message-special-info {
  @apply bg-amber-eronor
}

.message-special-info h2 {
  @apply text-slate-eronor
}

.message-special-cancel {
  @apply bg-red-eronor border-t-4 border-black
}

.section-message-event {
  @apply w-full flex relative overflow-hidden h-10 items-center shadow-sm shadow-white-eronor;

  /* Utilisez votre image de fond */
  will-change: transform;
  animation: animateBackground 6s ease-in-out infinite;
}

@keyframes animateBackground {
  0% {
    /* transform: scale(1); */
    filter: brightness(1);
    /* box-shadow: 0 0 20px rgba(255, 255, 255, 0.3); Léger effet lumineux */
  }

  20% {
    /* transform: scale(1.1); */
    filter: brightness(1.1);
    /* box-shadow: 0 0 30px rgba(255, 255, 255, 0.5); Effet lumineux plus fort */
  }

  40% {
    /* transform: scale(1); */
    filter: brightness(1.2);
    /* box-shadow: 0 0 40px rgba(255, 255, 255, 0.7); Forte lumière */
  }

  50% {
    /* transform: scale(1.1); */
    filter: brightness(1.5);
    /* box-shadow: 0 0 50px rgba(255, 255, 255, 0.9); Effet lumineux très fort */
  }

  60% {
    /* transform: scale(1); */
    filter: brightness(1.2);
    /* box-shadow: 0 0 30px rgba(255, 255, 255, 0.5); Légère réduction de la luminosité */
  }

  80% {
    /* transform: scale(1.1); */
    filter: brightness(1.1);
    /* box-shadow: 0 0 40px rgba(255, 255, 255, 0.7); Effet de lumière de transition */
  }

  100% {
    /* transform: scale(1); */
    filter: brightness(1);
    /* box-shadow: 0 0 20px rgba(255, 255, 255, 0.3); Retour à l'état initial */
  }
}

@keyframes slideText {
  0% {
    /* transform: translateX(100%); */
    /* Commence en dehors de l'écran à droite */
  }

  /* 40% {
    transform: translateX(0);


  }

  60% {
    transform: translateX(0);

  } */

  40% {
    /* transform: translateX(0) scale(1); */
    /* Atteint la position centrale avec un effet de grossissement */
  }

  50% {
    /* transform: translateX(0) scale(1.2); */
    /* Augmenter encore plus en taille */
  }

  60% {
    /* transform: translateX(0) scale(1); */
    /* Retour à une taille agrandie après 50% */
  }

  70% {
    /* transform: translateX(0) scale(1.2); */
    /* Un autre cycle de mise à l'échelle */
  }

  80% {
    /* transform: translateX(0) scale(1); */
    /* Encore un autre cycle */
  }

  100% {
    /* transform: translateX(-100%); */
    /* Part vers la gauche */
  }
}
</style>
