import products from '@/storage/products'
import stores from '@/storage/stores'
import { getStore } from '@/utils/getStore'
import { reactive, ref } from 'vue'
import { getProductToCategory } from './getProductToCategory'
import { dataLoaded } from '@/event/dataLoaded'
import { theme } from '@/event/theme/theme'
import { isNavbarVisible } from '@/event/isNavbarVisible'
import { isLoading } from '@/event/error/isLoading'
import { showSectionManagement } from '@/event/main/app/showSectionManagement'

const categoryIds = ref()
const catalogueBase = ref()

export async function loadData() {
  isLoading.value = true
  try {
    await getStore()

    if (stores.state.storeData) {
      catalogueBase.value = stores.state.storeData.category
      // console.log(catalogueBase.value)
      if (catalogueBase.value.length > 0) {
        categoryIds.value = catalogueBase.value.map(
          (category: { category_id: any }) => category.category_id
        )
      }

      if (products.getProductData().length === 0) {
        await getProductToCategory(categoryIds.value)
      }

      dataLoaded.value = 2
      if (theme.value !== 'default') {
        isNavbarVisible.value = true
      }
    }
  } catch (e) {
    dataLoaded.value = 1
    isLoading.value = false
  }
}
