import { reactive } from 'vue'

interface Customer {
  customer_id: number
  store_id: number
  language_id: number
  firstname: string
  lastname: string
  email: string
  telephone: string
  address_id: number
  status: number
}

const state = reactive({
  customerData: {} as Partial<Customer>
})

function clearCustomerData() {
  state.customerData = {}
}
function setCustomerData(data: Customer) {
  state.customerData = data
}

function getCustomerData() {
  return state.customerData
}

export function isCustomerDefined() {
  return !!state.customerData.customer_id
}

export type { Customer }

export default {
  state,
  clearCustomerData,
  setCustomerData,
  getCustomerData
}
