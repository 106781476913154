<script setup lang="ts">
import { isAuthenticated } from '@/event/account/auth/authentification';
import { messageDeleteAccount } from '@/event/account/alert/messageDeleteAccount';
import { showAlertDeleteAccount } from '@/event/account/alert/toggle/toggleAlertDeleteAccount';
import { messageAlert } from '@/event/alert/messageAlert';
import customer from '@/storage/customer';
import stores from '@/storage/stores';
import { formatStoreName } from '@/utils/store/formatStoreName';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { fetchPostAuthorization } from '@/utils/fetch/fetchPostAuthorization';
import { urlCustomerDelete } from '@/api/endpoint';
import { showSelectDeleteCustomer } from '@/event/account/delete/showSelectDeleteCustomer';


async function selectDeleteAccount() {
    showSelectDeleteCustomer.value = true
}

</script>

<template>
    <section class="section-delete-account">
        <button class="button-delete-account" @click="selectDeleteAccount">Supprimer son
            compte</button>
    </section>
</template>

<style scoped>
.section-delete-account {
    @apply w-full flex flex-col items-center pt-10
}

.button-delete-account {
    @apply flex bg-red-eronor p-2 rounded-md text-sm hover:bg-reddark-eronor shadow-white shadow-sm hover:shadow-md
}
</style>