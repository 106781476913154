import { URL_BACK } from '@/config/constants'
import { firstPartUrl } from '@/event/url/url'
import products from '@/storage/products'
import stores from '@/storage/stores'
import { fetchCsrf } from './csrf/fetchCsrf'
import { cartBus } from '@/event/cartBus'
import { fetchPost } from './fetch/fetchPost'
import { urlGetProduct } from '@/api/endpoint'

function removeBlockedProductsFromCart(blockedProductIds: number[]): void {
  if (blockedProductIds.length === 0) {
    return
  }

  // Vérifier si un ID bloqué est dans le panier avant suppression
  const productIdsInCart = cartBus.value.map((item) => item.product_id)
  const validBlockedIds = blockedProductIds.filter((id) => productIdsInCart.includes(id))

  if (validBlockedIds.length === 0) {
    return
  }

  // Supprimer seulement les produits qui sont dans le panier ET bloqués
  cartBus.value = cartBus.value.filter((item) => !validBlockedIds.includes(item.product_id))
}

export async function getProductToCategory(categoryIdArray: Array<number>) {
  try {
    const data = {
      category_id_array: categoryIdArray,
      store_id: stores.state.storeData.store_id,
      store_name: stores.state.storeData.name
    }
    const responseProduct = await fetchPost(urlGetProduct, data)

    if (responseProduct.class === 'success') {
      const fetchData = await responseProduct.data
      products.setProductData(fetchData.product)
      if (fetchData.product_blocked && Array.isArray(fetchData.product_blocked)) {
        await removeBlockedProductsFromCart(fetchData.product_blocked)
      }
    }
  } catch (e) {
    return
  }
}
